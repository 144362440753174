<template>
  <header>
    <h1>Podsumowanie</h1>
    <h4>podsumowanie oferty</h4>
  </header>
  <div class="row">
    <div class="col-80 ma">
      <base-card>
        <template #header>
          <h4>Podsumowanie oferty</h4>
        </template>
        <template #content>
          <form @submit.prevent="submitSummary">
            <div class="form-control">
              <label for="summary-name">Nazwa oferty</label>
              <input
                name="summary-name"
                type="text"
                id="summary-name"
                v-model="selectedName"
              />
            </div>
            <div class="form-control">
              <label for="summary-desc">Opis oferty</label>
              <textarea
                name="summary-desc"
                id="summary-desc"
                v-model="selectedDesc"
              ></textarea>
            </div>
          </form>
          <summary-item-list
            :items="offer.items"
            :taxVal="offer.taxVal"
          ></summary-item-list>
        </template>
      </base-card>
    </div>
  </div>
  <base-dialog
    @onPrimaryEvent="goBack"
    :primary="true"
    :onPrimary="'Powrót'"
    v-if="dialogIsOpen"
  >
    <template #header> Błąd konfiguracji oferty </template>
    <template #desc>
      {{ errorMsg }}
    </template>
  </base-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import BaseCard from "../components/base/BaseCard.vue";
import BaseDialog from "../components/base/BaseDialog.vue";
import SummaryItemList from "../components/summary/SummaryItemList.vue";

export default {
  components: { BaseDialog, BaseCard, SummaryItemList },
  data() {
    return {
      dialogIsOpen: false,
      errorMsg: "",
      selectedName: "Oferta sprzątania",
      selectedDesc:
        "Przedstawiamy Państwu ofertę na {{ xxxxxxx }}. Dokument obejmuje szczegółowy wykaz usług wraz z ich kosztem całkowitym. W razie jakichkolwiek pytań zapraszamy do kontaktu mailowego lub telefonicznego.",
      offer: {},
    };
  },
  computed: {
    ...mapGetters(["getOffer", "getOfferValue"]),
  },
  methods: {
    openWarning() {
      this.dialogIsOpen = true;
    },
    goBack() {
      this.dialogIsOpen = false;
      this.$router.push("/offers");
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.path === "/offers") {
      next();
    } else {
      next("/offers");
    }
  },
  beforeMount() {
    if (!this.getOfferValue) {
      this.errorMsg =
        "Wartość usług nie może być równa 0! Wprowadź odpowiednio ilość i wartość dla każdej z konfigurowanych usług i ponownie przejdź do podsumowania.";
      this.openWarning();
    } else {
      this.offer = this.getOffer;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../_base.scss";
</style>