<template>
  <li>
    <base-card>
      <template #content>
        <div class="summary-item">
          <div class="mr-2">
            <div class="mr-1"><b>Nazwa:</b></div>
            <div>{{ item.name }}</div>
          </div>
          <div class="mr-2">
            <div class="mr-1"><b>Ilość:</b></div>
            <div>{{ item.quantity }} {{ item.dimension }}</div>
          </div>
          <div class="mr-2">
            <div class="mr-1"><b>Koszt:</b></div>
            <div>{{ item.quantity * item.price }} zł</div>
          </div>
        </div>
      </template>
    </base-card>
  </li>
</template>

<script>
export default {
  props: ["item"],
};
</script>

<style lang="scss" scoped>
li {
  list-style: none;
}
.summary-item {
  display: flex;
  div {
    display: flex;
  }
}
</style>