<template>
  <ul>
    <summary-item
      class="mb-1"
      v-for="item of items"
      :key="item.id"
      :item="item"
    ></summary-item>
  </ul>
  <base-card class="mt-1">
    <template #header>
      <h4>Podsumowanie</h4>
    </template>
    <template #content>
      <div class="summary">
        <div class="mr-1">
          <b>Koszt całkowity: </b>
        </div>
        <div>{{ getFinalValue }} zł</div>
      </div>
      <div class="summary">
        <div class="mr-1">
          <b
            >Z uwzględnieniem podatku ryczałtowego {{ taxVal }}% (kwota netto):
          </b>
        </div>
        <div>{{ getFinalValueTaxed }} zł</div>
      </div>
    </template>
  </base-card>
</template>

<script>
import SummaryItem from "./SummaryItem.vue";

export default {
  props: ["items", "taxVal"],
  components: { SummaryItem },
  computed: {
    getFinalValue() {
      if (this.items) {
        let sum = 0;
        this.items.forEach((item) => {
          sum += item.price * item.quantity;
        });
        return sum;
      } else {
        return 0;
      }
    },
    getFinalValueTaxed() {
      return ((this.getFinalValue * 100) / (100 - this.taxVal)).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  padding: 0;
  li {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.summary {
  display: flex;
}
</style>